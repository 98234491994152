<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.modificators')">

            <div v-if="businessUnits.length <= 0">
                <b-alert show variant="warning" class="p-1 mt-2">
                    {{$t('keys.table_empty')}}<router-link to="business_units">{{$t('keys.here')}}</router-link>
                </b-alert>
            </div>
            <div v-else>
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" @click="openModificatorExplanation">
                        {{ $t('keys.modificators.modificator_explanation.explain') }}
                    </b-button>
                </div>
                <b-row>
                    
                    <!-- Izbira poslovalnice-->
                    <b-col v-if="businessUnits" cols="12" md="6">
                        <b-form-group v-if="businessUnits && businessUnits.length > 0" :label="$t('general.business_unit')">
                            <v-select v-model="businessUnit" label="name" :options="businessUnits" @input="modificator_changeSelectBusiness" :clearable="false"/>
                        </b-form-group>
                    </b-col>
     
                    <!-- Izbira modifikatorja-->
                    <b-col cols="12" md="6">
                        <b-form-group v-if="mods && mods.length > 0" :label="$t('general.modificator')">
                            <v-select v-model="modColumns" label="name" :options="mods" @input="modificator_changeSelectModificator" :clearable="false"/>
                        </b-form-group>
                        <div v-else-if="!showLoader">
                            <b-alert v-if="$hasPermission($permissions.ProductsWrite) && $hasPermission($permissions.PricesWrite)" show variant="warning" class="p-1 mt-2">
                                {{$t('keys.modificators.no_modificators_in_business_unit')}} <i>{{$t('keys.modificators.add_modificator')}}</i>.
                            </b-alert>
                        </div>
                    </b-col>
    
                    <!-- Opis modifikatorja-->
                    <b-col cols="12" md="6">
                        <div v-if="modColumns">
                            <h4>
                                <span class="font-weight-normal">{{$t('keys.valid_from')}}</span>
                                <span class="font-weight-bold text-primary"> {{dayjs(modColumns.valid_conditions.valid_from).format('DD.MM.YYYY')}}</span>
                                <span class="font-weight-normal"> {{$t('keys.until')}}</span>
                                <span class="font-weight-bold text-primary"> {{dayjs(modColumns.valid_conditions.valid_to).format('DD.MM.YYYY')}}</span>
                                <span v-if="checkTime">
                                    <span class="font-weight-normal"> {{$t('keys.between')}}</span>
                                    <span class="font-weight-bold text-primary"> {{dayjs(modColumns.valid_conditions.start_hour).utc().format('HH:mm')}}</span>
                                    <span class="font-weight-normal"> {{$t('keys.and')}}</span>
                                    <span class="font-weight-bold text-primary"> {{dayjs(modColumns.valid_conditions.end_hour).utc().format('HH:mm')}}</span>
                                    <span class="font-weight-normal"> {{$t('keys.hour')}}</span>
                                    <span class="font-weight-normal">.</span>
                                </span>
                                <span v-else>
                                    <span class="font-weight-normal">.</span>
                                </span>
                            </h4>
                            <div v-if="modColumns.valid_conditions.interval === 'monthly'" class="d-flex align-items-center">
                                <h4 class="font-weight-normal">{{$t('keys.valid_on_days')}}</h4>
                                <div class="ml-1 d-flex flex-wrap" style="gap: 1rem;">
                                    <h4 v-for="item of modColumns.valid_conditions.days" :key="'day_'+item">
                                        <b-badge variant="primary">{{daysOptions.find(x => x.value === item).text}}.</b-badge>
                                    </h4>
                                </div>
                                <h4 class="ml-1 font-weight-normal">
                                    {{$t('keys.day_in_month')}}.
                                </h4>
                            </div>
                            <div v-else-if="modColumns.valid_conditions.interval === 'weekly'" class="d-flex align-items-center">
                                <h4 class="font-weight-normal">{{$t('keys.valid_on_days')}}:</h4>
                                <div class="ml-1 d-flex flex-wrap" style="gap: 1rem;">
                                    <h4 v-for="item of modColumns.valid_conditions.weekdays" :key="'day_'+item">
                                        <b-badge variant="primary">{{weekdays.find(x => x.value === item).textFull}}</b-badge>
                                    </h4>
                                </div>
                            </div>
                            <h4 class="font-weight-normal">
                                {{$t('keys.priority')}}: <span class="font-weight-bold text-primary"> {{modColumns.priority}} </span>
                            </h4>
                            <h4 class="font-weight-normal">
                                {{$t('keys.modificators.discount_on_products')}}: <span class="font-weight-bold text-primary"> {{ modColumns.discount/100 }}%</span>
                            </h4>
                        </div>
                    </b-col>
                
                    <!-- Gumbi-->
                    <b-col lg:cols="6" class="d-flex flex-wrap align-items-start" v-if="$hasPermission($permissions.ProductsWrite) && $hasPermission($permissions.PricesWrite)">
                        <div class="d-flex w-100 justify-content-between">
                            <div v-if="modColumns && !modColumns.default" class="d-flex align-items-end">
                                <b-form-checkbox :switch="true" v-model="modColumns.active" @change="updateActive">
                                    <h4>
                                        <b-badge v-if="modColumns.active" variant="success" size="md">{{$t('keys.modificators.modificator_active')}}</b-badge>
                                        <b-badge v-else variant="danger" size="lg">{{$t('keys.modificators.modificator_inactive')}}</b-badge>
                                    </h4>
                                </b-form-checkbox>
                            </div>
                            <div class="d-flex" style="gap: 0.8rem;">
                                <b-button v-if="modColumns && !modColumns.default" variant="danger" @click="removeModificator">
                                    <feather-icon icon="TrashIcon"/>
                                </b-button>
                                <b-button v-if="modColumns && !modColumns.default" variant="secondary" @click="editModificator">
                                    <feather-icon icon="EditIcon"/>
                                </b-button>
                                <b-button variant="primary" @click="$refs.addModal.open()">
                                    <feather-icon icon="PlusSquareIcon"/>
                                    {{ $t('keys.modificators.add_modificator') }}
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <div class="d-flex align-items-center justify-content-between mt-2">
                    <h2 class="m-0">{{$t('menu.subcategory.products')}}</h2>
                    <b-button v-if="modColumns && !modColumns.default && $hasPermission($permissions.ProductsWrite)" variant="primary" @click="$refs.addProductsMod.open('Products');" >
                        <feather-icon icon="EditIcon"/>
                        {{ $t('keys.edit_products') }}
                    </b-button>
                </div>
                <Table name="products" class="mt-1" :columnDefs="columnDefsProducts" :rowData="modProducts" @deleteRow="removeProduct"/>

                <div class="d-flex align-items-center justify-content-between mt-2">
                    <h2 class="m-0">{{$t('menu.subcategory.categories')}}</h2>
                    <b-button v-if="modColumns && !modColumns.default && $hasPermission($permissions.ProductsWrite)" variant="primary" @click="$refs.addProductsMod.open('Categories');">
                        <feather-icon icon="EditIcon"/>
                        {{ $t('keys.edit_categories') }}
                    </b-button>
                </div>
                <Table name="category" class="mt-1" :columnDefs="columnDefs" :rowData="modCategories" @deleteRow="removeCategory"/>

                <div class="d-flex align-items-center justify-content-between mt-2">
                    <h2 class="m-0">{{$t('menu.subcategory.product_order_options')}}</h2>
                    <b-button v-if="modColumns && !modColumns.default && $hasPermission($permissions.ProductsWrite)" variant="primary" @click="$refs.addProductsMod.open('Order options categories');">
                        <feather-icon icon="EditIcon"/>
                        {{ $t('keys.edit_additions_categories') }}
                    </b-button>
                </div>
                <Table name="order_option_category" class="mt-1" :columnDefs="columnDefs" :rowData="modOrderOptionsCategories" @deleteRow="removeOrderOption"/>
            </div>
        </b-card>

        <AddMod ref="addModal" :business-unit-id="businessUnit.id" :mod="modColumns" v-on:itemAdded="loadData" @edit="onEditModificator"/>
        <add-prod-to-mod :productSubcategories="productSubcategories" :modificator="modColumns" ref="addProductsMod" v-on:itemAdded="loadData"></add-prod-to-mod>
        <ModificatorExplanation ref="modificatorExplanation"></ModificatorExplanation>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddMod from './AddModificator'
    import AddProdToMod from './AddProductToMod'
    import vSelect from 'vue-select'
    import {BBadge, BFormGroup, BFormCheckbox, BAlert, BButton, BCard, BCol, BOverlay, BRow } from 'bootstrap-vue'
    import ModificatorExplanation from '@/views/Modificator/ModificatorExplanation.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            ModificatorExplanation,
            BFormGroup,
            BBadge,
            BFormCheckbox,
            BAlert,
            vSelect,
            Table,
            AddMod,
            AddProdToMod,
            BRow,
            BCol,
            BCard,
            BOverlay,
            BButton},
        data() {
            return {
                productSubcategories: [],
                modColumns: null,
                products: [],
                mods: [],
                modProducts: [],
                modalProducts: [],
                modCategories: [],
                modOrderOptionsCategories: [],
                businessUnits: [],
                businessUnit: [],
                showLoader: false,
                type: '',
                firstLoad: false,
                isEditModificator: false
            }
        },
        methods: {
            getCategoryName(value) {
                if (value) {
                    const item = this.productSubcategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const resCategories = await  this.$http.get('/api/client/v1/product_subcategories/')

                    this.productSubcategories = resCategories.data ?? []

                    const respBusiness = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnits = respBusiness.data ?? []
                    
                    if (this.businessUnits && this.businessUnits.length > 0) {
                        this.businessUnit = this.businessUnits[0]
                    }
                    
                    const resp = await this.$http.get(`/api/client/v1/modificators/business_units/${this.businessUnit.id}`)
                    this.mods = resp.data ?? []
       
                    if (this.mods.length > 0) {
                        if (!this.firstLoad) {
                            this.modColumns = this.mods[0]
                            this.firstLoad = true
                        } else {
                            this.modColumns = this.mods.find((el) => el.id === this.modColumns.id)
                        }

                        await this.loadModificatorItems()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(`${this.$t('print.error.on_load_data')}`)
                } finally {
                    this.showLoader = false
                }
            },
            async modificator_changeSelectBusiness() {
                try {
                    this.showLoader = true
        
                    const response = await this.$http.get(`/api/client/v1/modificators/business_units/${this.businessUnit.id}`)
                    this.mods = response.data ?? []
        
                    if (this.mods && this.mods.length > 0) {
                        this.modColumns = this.mods[0]
                        this.loadModificatorItems()
                    } else {
                        this.modColumns = null
                        this.modProducts = []
                        this.modCategories = []
                        this.modOrderOptionsCategories = []
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async modificator_changeSelectModificator() {
                try {
                    this.showLoader = true
                    
                    this.loadModificatorItems()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadModificatorItems() {
                try {
                    this.showLoader = true
                    this.modProducts = []
                    this.modCategories = []
                    this.modOrderOptionsCategories = []
                    const res = await this.$http.get(`/api/client/v1/modificators/${this.modColumns.id}`)
                    if (res && res.data) {
                        this.modProducts = res.data.products ?? []
                        this.modCategories = res.data.categories ?? []
                        this.modOrderOptionsCategories = res.data.order_options_categories ?? []
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async updateActive() {
                try {
                    const thisIns = this
                    thisIns.showLoader = true
                    await this.$http.post(`/api/client/v1/modificators/${thisIns.modColumns.id}`, thisIns.modColumns)
                    thisIns.$printSuccess(thisIns.$t('general.edit_success'))
                    thisIns.showLoader = false
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            openModificatorExplanation() {
                this.$refs.modificatorExplanation.open()
            },
            onEditModificator() {
                this.isEditModificator = true
            },
            editModificator() {
                if (this.modColumns) this.$refs.addModal.open(this.modColumns)
            },
            removeModificator() {
                if (this.modColumns) this.remove(this.modColumns.id)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('keys.modificators.confirm.delete_modificator'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/modificators/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    this.firstLoad = false
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            edit(params) {
                try {
                    const thisIns = this
                    thisIns.showLoader = true
                    
                    const editObject = {object_data: {[params.column.colId]: params.newValue}}

                    this.$http.patch(`/api/client/v1/product/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                    thisIns.showLoader = false
                    this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }

            },
            async removeProduct(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/modificators/${this.modColumns.id}/products/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async removeCategory(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/modificators/${this.modColumns.id}/categories/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async removeOrderOption(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/modificators/${this.modColumns.id}/order_options/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            }
        },
        computed: {
            checkTime() {
                if (this.modColumns.valid_conditions.start_hour.substring(11, 19) === '00:00:00') {
                    if (this.modColumns.valid_conditions.end_hour.substring(11, 19) === '23:59:59') {
                        return false
                    }
                }
                return true
            },
            optionsTime() {
                return this.$store.getters['priceList/optionsTime']
            },
            repeatInterval() {
                return this.$store.getters['priceList/repeatInterval']
            },
            weekdays() {
                return this.$store.getters['priceList/weekdays']
            },
            daysOptions() {
                return this.$store.getters['priceList/daysOptions']
            },
            columnDefs() {
                const defs = [{ headerName: this.$t('table_fields.name'), field: 'name', filter: true, editable: false}]

                if (this.$hasPermission(this.$permissions.ProductsWrite)) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: () => { return {button: 'priceList', noDelete: this.editRow} } }
                    )
                }

                return defs
            },
            columnDefsProducts() {
                const defs = [
                    { headerName: this.$t('table_fields.name'), field: 'name', filter: true, editable: false},
                    { headerName: this.$t('table_fields.subcategory'), editable: false, field: 'product_subcategory', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productSubcategories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productSubcategories} }, cellRenderer: (params) => this.getCategoryName(params.value)}
                ]

                if (this.$hasPermission(this.$permissions.ProductsWrite)) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: () => { return {button: 'priceList', noDelete: this.editRow} } }
                    )
                }

                return defs
            }
        },
        async mounted() {
            await this.loadData()
            //this.loadOrderOptions()
            //this.loadOrderOptionsCategories()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>